import firebase from "firebase/app";
import { database } from "firebase/database";

//Production Database
// const firebaseApp = firebase.initializeApp({
//   apiKey: "AIzaSyDCHRIYwCqzsPi2e_wETZ17pPmDAgXFvaI",
//   authDomain: "adidasbooking5.firebaseapp.com",
//   databaseURL: "https://adidasbooking5.firebaseio.com",
//   projectId: "adidasbooking5",
//   storageBucket: "adidasbooking5.appspot.com",
//   messagingSenderId: "912851114808",
//   appId: "1:912851114808:web:c687bb35fb596a36833fcd",
//   measurementId: "G-42JGM5PT2P"
// });

//Test Database
const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyA7K0qRYXnezZzr3TOoD3ZPCEtqw5naDug",
  authDomain: "booking5test-b0a76.firebaseapp.com",
  databaseURL: "https://booking5test-b0a76.firebaseio.com",
  projectId: "booking5test-b0a76",
  storageBucket: "booking5test-b0a76.appspot.com",
  messagingSenderId: "883260075734",
  appId: "1:883260075734:web:3b72959bc2e9e400fae377"
});

const db = firebaseApp.database();

export const getServerPass = async () => {
  let passRef = db.ref(`/globalSettings/public/webSecurity/greenGrass`);
  var serverPass;
  await passRef.once("value", (snapshot) => {
    serverPass = snapshot.val() || ["None Found"];
  });
  return serverPass;
};

export const getDefaultSeason = async () => {
  let defaultSeasonRef = db.ref(`/globalSettings/public/defaultSeason`);
  let defaultSeason;
  await defaultSeasonRef.once("value", (snapshot) => {
    defaultSeason = snapshot.val() || "None Found";
  });
  return defaultSeason;
};

export const getSeasons = async () => {
  let seasonsRef = db.ref(`/globalSettings/public/seasons`);
  let seasons;
  await seasonsRef.once("value", (snapshot) => {
    seasons = snapshot.val() || {};
  });
  return seasons;
};

export const getArticlesFor = async (season, subCategory) => {
  let fullSeasonName = season.concat("_", subCategory);
  let articlesRef = db.ref(`articles/${fullSeasonName}`);
  let articles;
  await articlesRef.once("value", (snapshot) => {
    articles = snapshot.val() || {};
  });
  return articles;
};

export default db;
